import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"
// import scene from "./scene-min.svg"

const HomeSection1 = () => (
    <section className={classnames("bg-light", styles.wave)}>
        <div className={classnames(styles.wrapper, "d-flex flex-wrap content")}>
            <div className={styles.text}>
                <h1>Our mission is to help you move home.</h1>
                <h2>Your belongings are in safe hands.</h2>
                <p>
                    When it comes to helping you move your cherished belongings, we are there for you from pick up to delivery. 
                    You can enjoy a stress-free removal from start to finish. No job is too small or big for The Removal Man.
                </p>
            </div>
            <div className={styles.img}></div>
        </div>
    </section>
)

export default HomeSection1