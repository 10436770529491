import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"
import { Link } from "gatsby"
import smallvan from "./small-van.jpg"
import mediumvan from "./long-wheelbase-van.jpg"
import lutonvan from "./luton-van.jpg"
import { inject, observer } from "mobx-react"

const Service = ({ title, img, url }) => (
    <Link to={url} className={classnames(styles.service, "paper-low")}>
        <img alt={`${title} Service`} src={img} />
        <h3>{title}</h3>
    </Link>
)


const HomeSection3 = inject(stores => ({
    context: stores.context_store
}))(
    observer(({ context }) => (
        <section className={classnames("bg-light", styles.wrapper)}>
            <div className="content">
                <h2>Simple, Affordable<span> Prices.</span></h2>
                <h3>Topped off with a 25,000£ Warranty</h3>
                <p className="lead alt">
                    Our prices are fair and simple. You choose which <strong>van</strong> you need and tell us how many <strong>men</strong> you need for the job.
            We take care of the rest.
            </p>
                {context.version === "2" ? (
                    <p>To make it even simpler for you, we have built an <em>instant online quote</em> calculator that helps you find the ideal combination of men and van. Click below to use it!</p>
                ) : null}
                <div className={classnames("d-flex flex-wrap justify-content-center", styles.services)}>
                    <Service title="Small Van" url={`/quote`} img={smallvan} />
                    <Service title="Long Wheelbase Van" url={`/quote`} img={mediumvan} />
                    <Service title="Luton Van" url={`/quote`} img={lutonvan} />
                </div>
                <Link to="/quote" className="btn-cta hover-drop">
                    {context.version === "2" ? "Instant Online Quote" : "Get a Quote"}
                </Link>
            </div>
        </section>
    ))
)

export default HomeSection3