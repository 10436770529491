import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"
import Reviews from "../Reviews";
import LazyLoader from "../LazyLoader";

const HomeSection4 = () => (
    <section id="testimonials" className={classnames("content bg-white", styles.wrapper)}>
        <div className={classnames(styles.text, "content")}>
            <h2>Be a <span>happy</span> homeowner.</h2>
            <h3>Stop worrying, start saving.</h3>
            <p className="lead">
                Join hundreds of happy clients we have served for over a decade.
            </p>
            <p>
                When moving your belongings, we guarantee the best prices and the most diligent care.
            </p>
        </div>
        <LazyLoader id="home-reviews">
            <Reviews paper />
        </LazyLoader>

    </section>
)

export default HomeSection4