import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HomeSection1 from "../components/HomeSection1"
import HomeSection2 from "../components/HomeSection2"
import HomeSection3 from "../components/HomeSection3"
import HomeSection4 from "../components/HomeSection4"
import Chat from "../components/Chat"
import { Provider } from "mobx-react"
import ChatStore from "../stores/chat.store"

class IndexPage extends React.Component {

  constructor(props) {
    super(props)
    this.stores = {
      chat: new ChatStore()
    }
  }

  render() {
    return (
      <Provider
        chat_store={this.stores.chat}
      >
        <Layout gray>
          <SEO title="Home" />

          <Chat />

          <HomeSection1 />
          <HomeSection2 />
          <HomeSection3 />
          <HomeSection4 />
        </Layout>
      </Provider>
    )
  }
}

export default IndexPage
