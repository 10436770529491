import React from "react"
import { observable, action } from "mobx"
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import { systemInitializerFactory, TriggerInstance } from "../PositionalTrigger"
import styles from "./style.module.css"
import classnames from "classnames"

const startTrigger = (id) => `lazy-loader-${id}-start`
const endTrigger = (id) => `lazy-loader-${id}-end`

@observer
class LazyLoader extends React.Component {

    @observable loaded = false


    constructor(props) {
        super(props)
        this.triggers = systemInitializerFactory([startTrigger(props.id), endTrigger(props.id)], this.handleLazyLoading)
    }

    @action.bound
    handleLazyLoading(api) {
        if (this.loaded) return
        const start = startTrigger(this.props.id)
        const end = endTrigger(this.props.id)
        this.loaded = api.between(start, end) ||
            api.isVisible(start) ||
            api.isVisible(end)
    }

    render() {
        const {triggerPctOffset, children } = this.props
        const startStyleOverride = triggerPctOffset ? ({ top: `-${triggerPctOffset}` }) : null
        const endStyleOverride = triggerPctOffset ? ({ bottom: `-${triggerPctOffset}` }) : null
        return (
            <div className="position-relative">
                <TriggerInstance instance={this.triggers[0]} className={classnames("position-absolute", styles.start)} style={{ ...startStyleOverride }} />
                {this.loaded ? children : null}
                <TriggerInstance instance={this.triggers[1]} className={classnames("position-absolute", styles.end)} style={{ ...endStyleOverride }} />
            </div>
        )
    }

}


LazyLoader.propTypes = {
    id: PropTypes.string.isRequired,
    triggerPctOffset: PropTypes.string
}

export default LazyLoader