import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"
import { Link } from "gatsby"

const HomeSection2 = () => (
    <section className={classnames("bg-white")}>
        <div className={classnames("content d-flex flex-wrap", styles.wrapper)}>
            <div className={styles.scene}>
                <span className={styles.mainPlane} />
                {/* <span className={styles.mainPlaneShadow} /> */}
                <span className={styles.signPost} />
                <span className={styles.signPostShadow} />
                <span className={classnames(styles.smallPlane, styles.one, styles.green)} />
                <span className={classnames(styles.smallPlane, styles.two, styles.grey)} />
                <span className={classnames(styles.smallPlane, styles.three, styles.grey)} />
                <span className={classnames(styles.smallPlane, styles.four, styles.grey)} />
                <span className={classnames(styles.smallPlane, styles.five, styles.altGreen)} />
            </div>
            {/* <img src={scene} alt="We offer our services across London. Join hundreds of others and save money" /> */}
            <div className={styles.text}>
                <h2 id="test">Whether you are moving around the UK or across the channel. <span>We are here for you.</span></h2>
                <p className="lead">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam cursus mi vel laoreet dictum.
                    Maecenas egestas facilisis condimentum. Curabitur diam erat, efficitur vel risus ut, tristique lobortis libero.
                    Duis lectus elit, semper rhoncus pharetra id, ornare eu eros. Quisque tempus, massa in dapibus lobortis, sem nulla egestas ex,
                    a tristique nibh felis vel urna. */}
                    For over a decade we helped people in the United Kingdom move their cherished belongings around the country and across the channel.
                </p>
                <p>
                    Give us a call for international removals, or use our <em>instant quote calculator</em> if relocating across the UK.
                </p>
                <Link to="/quote" className="btn-cta hover-drop">
                    Help me move
                </Link>
            </div>
        </div>
    </section>
)

export default HomeSection2